import Aim from "../Components/Aim";
import Contact from "../Components/Contact";
import WhoAreWe from "../Components/WhoAreWe";

const About = () => {
  return (
    <div>
      <WhoAreWe />
      {/* <Aim /> */}
      <Contact />
    </div>
  )
};

export default About;